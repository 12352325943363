import React from "react";
import { Form } from "react-bootstrap";

export default function EasybuyVersaoMatricula({
  allVersoesSelected,
  setAllVersoesSelected,
  allVersoesMatricula,
}) {
  const onChangeVersao = (event) => {
    setAllVersoesSelected(JSON.parse(event.target.value));
  };

  return (
    <>
      <Form.Select
        onChange={(e) => {
          onChangeVersao(e);
        }}
        isValid={allVersoesSelected.length !== 0}
      >
        <option value="">Selecione</option>
        {allVersoesMatricula.map((item) => {
          return (
            <option key={item.Nome} value={JSON.stringify(item)}>
              {item.Nome}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </>
  );
}
