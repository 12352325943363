import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { ReactComponent as InicioOpiniao } from "../../../assets/icons/testemunhos/inicio_opiniao.svg";
import { ReactComponent as FimOpiniao } from "../../../assets/icons/testemunhos/fim_opiniao.svg";
import { ReactComponent as Opiniao } from "../../../assets/icons/testemunhos/opiniao.svg";

import { tratarDataManutencao } from "../../helpers/tratarInfoCarros";
import css from "./testemunho.module.css";
export default function CardTestemunhos({ item, infoJson }) {
  const [readMore, setReadMore] = useState(true);

  const { Data, Localidade, Nome, Texto } = item;

  const data = tratarDataManutencao(Data);

  useEffect(() => {
    Texto.length > 250 && setReadMore(false);
  }, [Texto]);

  return (
    <>
      <Col className={`listagem_info ${css.box}`}>
        <Col className={`${css.texto} mb-4`}>
          <InicioOpiniao style={{ marginRight: "5px" }} />

          {readMore ? (
            <span
              dangerouslySetInnerHTML={{
                __html: Texto,
              }}
            />
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: `${Texto.substring(0, 250)}`,
              }}
            />
          )}
          {!readMore && `...`}
          {!readMore && (
            <div
              className={`titulo-page ${css.verMais}`}
              onClick={() => setReadMore(!readMore)}
            >
              Ver mais
            </div>
          )}
          <FimOpiniao style={{ marginLeft: "5px" }} />
        </Col>
        <Col>
          <Row className="d-flex align-items-start">
            <Col
              xs={{ span: 12, order: "last" }}
              xxl={{ span: 6, order: "first" }}
              className={css.Op}
            >
              <div>
                <Opiniao
                  stroke={infoJson.Cores.Principal}
                  style={{ marginRight: "10px" }}
                />
              </div>

              <div>
                <div className={`${css.titulo} text-truncate`}>{Nome}</div>
                <div className={`${css.Localidade} text-truncate`}>
                  {Localidade}
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 12, order: "first" }}
              xxl={{ span: 6, order: "last" }}
              className={`${css.data} `}
            >
              {data}
            </Col>
          </Row>
        </Col>
      </Col>
    </>
  );
}
