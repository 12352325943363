import { getIn } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

export default function EasybuyConcelhos({
  errors,
  handleChange,
  setFieldValue,
  touched,
  allConcelho,
}) {
  return (
    <>
      <Form.Select
        onChange={(e) => {
          handleChange.onChange(e);
          setFieldValue(
            "concelho.nome",
            e.target[e.target.selectedIndex].getAttribute("data-value")
          );
        }}
        name={handleChange.name}
        onBlur={handleChange.onBlur}
        value={handleChange.value}
        isInvalid={
          Boolean(getIn(errors, "concelho.id")) &&
          Boolean(getIn(touched, "concelho.id"))
        }
        isValid={handleChange.value !== ""}
      >
        <option value="" data-value="">
          Selecione
        </option>
        {allConcelho.map((concelho) => {
          const { Codigo, Nome } = concelho;
          return (
            <option key={Codigo} value={Codigo} data-value={Nome}>
              {Nome}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {getIn(errors, "concelho.id")}
      </Form.Control.Feedback>
    </>
  );
}
