import { getIn } from "formik";
import React, { useEffect } from "react";

import { Form } from "react-bootstrap";

export default function EasybuyModelos({
  allModelo,
  errors,
  setFieldValue,
  handleChange,
  touched,
  values,
  onChangeModelos,
  allVersoesSelected,
}) {
  useEffect(() => {
    if (allVersoesSelected.length !== 0) {
      setFieldValue("modelo.id", allVersoesSelected.Cod_modelo);
      setFieldValue("modelo.nome", allVersoesSelected.Modelo);
    }
  }, [allVersoesSelected, setFieldValue]);

  const onChangeReset = () => {
    setFieldValue("combustivel.nome", "");
    setFieldValue("combustivel.id", "");
    setFieldValue("potencia", "");
    setFieldValue("cilindrada", "");
    setFieldValue("porta.nome", "");
    setFieldValue("porta.id", "");
    setFieldValue("transmissao.nome", "");
    setFieldValue("transmissao.id", "");
    setFieldValue("versao.nome", "");
    setFieldValue("versao.id", "");
    setFieldValue("lugar.nome", "");
    setFieldValue("lugar.id", "");
  };

  return (
    <>
      <Form.Select
        onChange={(e) => {
          handleChange.onChange(e);
          onChangeReset();
          setFieldValue(
            "modelo.nome",
            e.target[e.target.selectedIndex].getAttribute("data-value")
          );
          onChangeModelos(e);
        }}
        name={handleChange.name}
        onBlur={handleChange.onBlur}
        value={handleChange.value}
        isInvalid={
          Boolean(getIn(errors, "modelo.id")) &&
          Boolean(getIn(touched, "modelo.id"))
        }
        isValid={handleChange.value !== ""}
        disabled={values.marca.id !== "" ? false : true}
      >
        <option value="" data-value="">
          Selecione
        </option>
        {allModelo.map((modelo) => {
          const { CodModelo, Modelo } = modelo;
          return (
            <option key={CodModelo} value={CodModelo} data-value={Modelo}>
              {Modelo}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {getIn(errors, "modelo.id")}
      </Form.Control.Feedback>
    </>
  );
}
