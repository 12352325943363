import React from "react";
import { ReactComponent as Fechar } from "../../../../assets/icons/modal/fechar.svg";
import { ReactComponent as Perg } from "../../../../assets/icons/menu/serv_consult.svg";
import { ReactComponent as Func } from "../../../../assets/icons/menu/funciona.svg";
import { ReactComponent as SvgTestemunhos } from "../../../../assets/icons/servicos/svg_testemunhos.svg";
import { NavLink } from "react-router-dom";
import cssF from "../../../modal/faleConnosco.module.css";

export default function MenuServicosBottom({
  onHide,
  infoJson,
  allinfoText,
  nodeRef,
}) {
  return (
    <>
      <div className={cssF.zone}></div>
      <section
        ref={nodeRef}
        className={` ${cssF.box} ${cssF.height} listagem_info `}
      >
        <div onClick={() => onHide()} className={` ${cssF.close} close`}>
          <Fechar />
        </div>
        <section className={cssF.retangle}>
          <ul className={``}>
            {infoJson.PerguntasFrequentes.existe && (
              <li>
                <NavLink
                  onClick={onHide}
                  className={(navData) =>
                    navData.isActive ? "active boxMenuItem" : "boxMenuItem"
                  }
                  to={
                    allinfoText.PerguntasFrequentes &&
                    allinfoText.PerguntasFrequentes.Url !== ""
                      ? allinfoText.PerguntasFrequentes.Url
                      : infoJson.PerguntasFrequentes.url
                  }
                >
                  <Perg />{" "}
                  {allinfoText.PerguntasFrequentes &&
                  allinfoText.PerguntasFrequentes.Titulo !== ""
                    ? allinfoText.PerguntasFrequentes.Titulo
                    : infoJson.PerguntasFrequentes.titulo}
                </NavLink>
              </li>
            )}
            {infoJson.ComoFunciona.existe && (
              <li>
                <NavLink
                  onClick={onHide}
                  className={(navData) =>
                    navData.isActive ? "active boxMenuItem" : "boxMenuItem"
                  }
                  to={
                    allinfoText.ComoFunciona &&
                    allinfoText.ComoFunciona.Url !== ""
                      ? allinfoText.ComoFunciona.Url
                      : infoJson.ComoFunciona.url
                  }
                >
                  <Func />{" "}
                  {allinfoText.ComoFunciona &&
                  allinfoText.ComoFunciona.Titulo !== ""
                    ? allinfoText.ComoFunciona.Titulo
                    : infoJson.ComoFunciona.titulo}
                </NavLink>
              </li>
            )}
            {infoJson.Testemunhos.existe && (
              <li>
                <NavLink
                  onClick={onHide}
                  className={(navData) =>
                    navData.isActive ? "active boxMenuItem" : "boxMenuItem"
                  }
                  to={
                    allinfoText.Testemunhos &&
                    allinfoText.Testemunhos.Url !== ""
                      ? allinfoText.Testemunhos.Url
                      : infoJson.Testemunhos.url
                  }
                >
                  <SvgTestemunhos />{" "}
                  {allinfoText.Testemunhos &&
                  allinfoText.Testemunhos.Titulo !== ""
                    ? allinfoText.Testemunhos.Titulo
                    : infoJson.Testemunhos.titulo}
                </NavLink>
              </li>
            )}
          </ul>
        </section>
      </section>
    </>
  );
}
