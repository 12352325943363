import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { ReactComponent as Topo } from "../../../../assets/icons/menu/voltar_topo.svg";
import { ReactComponent as Emp } from "../../../../assets/icons/menu/emp.svg";
import { ReactComponent as Serv } from "../../../../assets/icons/menu/serv.svg";
import { ReactComponent as Contact } from "../../../../assets/icons/menu/falar_agora.svg";
import css from "../header.module.css";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import MenuServicosBottom from "./MenuServicosBottom";
import MenuViaturaBottom from "./MenuViaturaBottom";
export default function MenuBottom({
  infoJson,

  isScroll,
  addClass,
  setModalFaleConnosco,
  modalFaleConnosco,
  allinfoText,
  ficha,
  disabled,
  setDisabled,
}) {
  const nodeRef = React.useRef(null);
  const nodeRefV = React.useRef(null);
  const [activeDropS, setactiveDropS] = useState(false);
  const [activeDropV, setactiveDropV] = useState(false);

  useEffect(() => {
    //bloqueia o body
    //para não haver scroll na pagina
    activeDropV || activeDropS
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [activeDropV, activeDropS]);

  const openServicos = () => {
    activeDropS ? setactiveDropS(false) : setactiveDropS(true);
    activeDropS ? setDisabled(false) : setDisabled(true);
    setactiveDropV(false);
    setModalFaleConnosco(false);
  };

  const openModal = () => {
    modalFaleConnosco
      ? setModalFaleConnosco(false)
      : setModalFaleConnosco(true);
    modalFaleConnosco ? setDisabled(false) : setDisabled(true);
    setactiveDropS(false);
    setactiveDropV(false);
  };

  const goTop = () => {
    document.documentElement.scrollTo(0, 0);
  };

  return (
    <>
      <Navbar
        className={` ${css.cor_fundo_claro_bottom} corFundoClaroBottom ${
          !isScroll && !ficha ? css.navbarScrollCustomBottom : ""
        } ${addClass}`}
        fixed="bottom"
      >
        <Container>
          <div className="d-flex flex-nowrap w-100">
            {infoJson.QuemSomos.existe && (
              <div className={css.padding}>
                <Nav.Link
                  as={NavLink}
                  to={
                    allinfoText.QuemSomos && allinfoText.QuemSomos.Url !== ""
                      ? allinfoText.QuemSomos.Url
                      : infoJson.QuemSomos.url
                  }
                >
                  <div
                    className={`${css.boxMenu} ${css.boxMenuBottom} ${
                      disabled ? " disable" : ""
                    }  boxMenuMobile`}
                  >
                    <Emp className=" p-0 mb-2 " height={`27px`} />
                    {allinfoText.QuemSomos &&
                    allinfoText.QuemSomos.Titulo !== ""
                      ? allinfoText.QuemSomos.Titulo
                      : infoJson.QuemSomos.titulo}
                  </div>
                  <span></span>
                </Nav.Link>
              </div>
            )}
            <div className={css.padding}>
              <div
                className={`${css.boxMenu} ${css.boxMenuBottom} ${
                  disabled ? " disable" : ""
                }  boxMenuMobile`}
                onClick={() => {
                  openServicos();
                }}
              >
                <Serv className=" p-0 mb-2 " height={`27px`} />
                Serviços
              </div>
              <span></span>
            </div>
            <div className={css.padding}>
              <div
                className={`${css.boxMenu} ${disabled ? " disable" : ""} ${
                  css.boxMenuBottom
                } ${
                  modalFaleConnosco ? `${css.active} active ` : ""
                } boxMenuMobile`}
                onClick={() => {
                  openModal();
                }}
              >
                <Contact className=" p-0 mb-2 " />
                Falar agora
              </div>
              <span></span>
            </div>
            <div className={css.padding}>
              <div
                className={`${css.boxMenu} ${css.boxMenuBottom} ${
                  disabled ? " disable" : ""
                }  boxMenuMobile`}
                onClick={() => {
                  goTop();
                }}
              >
                <Topo className=" p-0 mb-2 " />
                Go Top
              </div>
              <span></span>
            </div>
          </div>
        </Container>
      </Navbar>

      <CSSTransition
        nodeRef={nodeRef}
        in={activeDropS}
        unmountOnExit
        timeout={100}
        classNames={{
          enterActive: `${css.opcoesEnter}`,
          enterDone: `${css.opcoesEnterActive}`,
          exit: `${css.opcoesExit}`,
          exitActive: `${css.opcoesExitActive}`,
        }}
      >
        <MenuServicosBottom
          onHide={() => {
            setactiveDropS(false);
            setDisabled(false);
          }}
          infoJson={infoJson}
          nodeRef={nodeRef}
          allinfoText={allinfoText}
        />
      </CSSTransition>
      <CSSTransition
        nodeRef={nodeRefV}
        in={activeDropV}
        unmountOnExit
        timeout={100}
        classNames={{
          enterActive: `${css.opcoesEnter}`,
          enterDone: `${css.opcoesEnterActive}`,
          exit: `${css.opcoesExit}`,
          exitActive: `${css.opcoesExitActive}`,
        }}
      >
        <MenuViaturaBottom
          onHide={() => {
            setactiveDropV(false);
            setDisabled(false);
          }}
          infoJson={infoJson}
          nodeRef={nodeRefV}
        />
      </CSSTransition>
    </>
  );
}
