import axios from "axios";
const global = require("../config/config");

async function getAllConcelhos(codDistritos, infoJson) {
  try {
    const API_URL_GETCONCELHOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetConcelhos/`,
      params: { dealer_id: infoJson.Stand.Anunciante, cod_zona: codDistritos },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETCONCELHOS);

    if (res.data.length > 0) {
      const concelhos = res.data;
      return concelhos;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getAllDistritos(infoJson) {
  try {
    const API_URL_GETDISTRITOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetDistritos/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETDISTRITOS);

    if (res.data.length > 0) {
      const distritos = res.data.map((distrito) => {
        return { ...distrito };
      });
      return distritos;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getAllMarcas(infoJson) {
  try {
    const API_URL_GETMARCAS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetMarcasAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETMARCAS);

    if (res.data.length > 0) {
      const marcas = res.data.map((marca) => {
        return { ...marca };
      });
      return marcas;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}
async function getAllModelos(codMarca, infoJson) {
  try {
    const API_URL_GETMODELOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetModelosAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante, brand_id: codMarca },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETMODELOS);

    if (res.data.length > 0) {
      const modelos = res.data;
      return modelos;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getExtrasViaturas(codViatura, infoJson) {
  try {
    const API_URL_EXTRAS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetExtrasViaturaFamilia/`,
      params: { dealer_id: infoJson.Stand.Anunciante, cars_id: codViatura },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_EXTRAS);

    if (res.data.length > 0) {
      const extras = res.data.map((extra) => {
        return { ...extra };
      });
      return extras;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getAllCombustivel(infoJson) {
  try {
    const API_URL_GETCOMBUSTIVEL = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetCombustivelAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETCOMBUSTIVEL);

    if (res.data.length > 0) {
      const combustiveis = res.data.map((combustivel) => {
        return { ...combustivel };
      });

      return combustiveis;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getAllPortas(infoJson) {
  try {
    const API_URL_GETPORTAS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetPortasAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETPORTAS);

    if (res.data.length > 0) {
      const portas = res.data.map((porta) => {
        return { ...porta };
      });

      return portas;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getAllTransmissao(infoJson) {
  try {
    const API_URL_GETTRANSMISSAO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetTransmissoesAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETTRANSMISSAO);

    if (res.data.length > 0) {
      const transmissoes = res.data.map((transmissao) => {
        return { ...transmissao };
      });

      return transmissoes;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getAllLugares(infoJson) {
  try {
    const API_URL_GETLugares = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetLugaresAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETLugares);

    if (res.data.length > 0) {
      const Lugares = res.data.map((lugar) => {
        return { ...lugar };
      });

      return Lugares;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getAllCores(infoJson) {
  try {
    const API_URL_GETCORES = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetCoresAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETCORES);

    if (res.data.length > 0) {
      const cores = res.data.map((cor) => {
        return { ...cor };
      });

      return cores;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function GetEquipamentos(infoJson) {
  try {
    const API_URL_GETEQUIPAMENTOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetEquipamentos/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETEQUIPAMENTOS);

    if (res.data.length > 0) {
      const equipamentos = res.data.map((equipamento) => {
        return { ...equipamento };
      });

      return equipamentos;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getModeloEurotax(marca, ano) {
  try {
    const API_URL_GETMODELO = {
      method: "GET",
      url: `${global.ApiLinkCatalogo}/v1/easydata/eurotax/GetModelsByYear/`,
      params: { marca: marca, ano: ano },
      headers: { token: process.env.REACT_APP_TOKEN_API_API },
    };

    const res = await axios.request(API_URL_GETMODELO);

    if (res.data.length > 0) {
      const modelos = res.data.map((modelo) => {
        return {
          CodModelo: modelo.id,
          Modelo: modelo.name,
        };
      });
      return modelos;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getCombustivelEurotax(marca, modelo, ano) {
  try {
    const API_URL_GETCOMBUSTIVEL = {
      method: "GET",
      url: `${global.ApiLinkCatalogo}/v1/easydata/eurotax/GetFuelFromVersions/`,
      params: { marca: marca, modelo: modelo, ano: ano },
      headers: { token: process.env.REACT_APP_TOKEN_API_API },
    };
    const res = await axios.request(API_URL_GETCOMBUSTIVEL);

    if (res.data.length > 0) {
      const combustiveis = res.data.map((combustivel) => {
        return {
          CodCombustivel: combustivel.id,
          Combustivel: combustivel.name,
        };
      });
      return combustiveis;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getPotenciaEurotax(marca, modelo, ano, combustivel) {
  try {
    const API_URL_GETPOTENCIA = {
      method: "GET",
      url: `${global.ApiLinkCatalogo}/v1/easydata/eurotax/GetPowerFromVersions/`,
      params: {
        marca: marca,
        modelo: modelo,
        ano: ano,
        combustivel: combustivel,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API_API },
    };
    const res = await axios.request(API_URL_GETPOTENCIA);

    if (res.data.length > 0) {
      const potencia = res.data.map((potencia) => {
        return {
          Potencia: potencia.id,
        };
      });
      return potencia;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getCilindradaEurotax(marca, modelo, ano, combustivel, potencia) {
  try {
    const API_URL_GETCILINDRADA = {
      method: "GET",
      url: `${global.ApiLinkCatalogo}/v1/easydata/eurotax/GetEngineFromVersions/`,
      params: {
        marca: marca,
        modelo: modelo,
        ano: ano,
        combustivel: combustivel,
        potencia: potencia,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API_API },
    };
    const res = await axios.request(API_URL_GETCILINDRADA);

    if (res.data.length > 0) {
      const cilindrada = res.data.map((cilindrada) => {
        return {
          Cilindrada: cilindrada.id,
        };
      });
      return cilindrada;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getPortasEurotax(
  marca,
  modelo,
  ano,
  combustivel,
  potencia,
  cilindrada
) {
  try {
    const API_URL_GEPORTAS = {
      method: "GET",
      url: `${global.ApiLinkCatalogo}/v1/easydata/eurotax/GetDoorsFromVersions/`,
      params: {
        marca: marca,
        modelo: modelo,
        ano: ano,
        combustivel: combustivel,
        potencia: potencia,
        cilindrada: cilindrada,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API_API },
    };
    const res = await axios.request(API_URL_GEPORTAS);

    if (res.data.length > 0) {
      const portas = res.data.map((porta) => {
        return {
          CodPorta: porta.id,
          Porta: porta.name,
        };
      });
      return portas;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getTransmissaoEurotax(
  marca,
  modelo,
  ano,
  combustivel,
  potencia,
  cilindrada,
  portas
) {
  try {
    const API_URL_GETTRANSMISSAO = {
      method: "GET",
      url: `${global.ApiLinkCatalogo}/v1/easydata/eurotax/GetTransmissionFromVersions/`,
      params: {
        marca: marca,
        modelo: modelo,
        ano: ano,
        combustivel: combustivel,
        potencia: potencia,
        cilindrada: cilindrada,
        portas: portas,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API_API },
    };
    const res = await axios.request(API_URL_GETTRANSMISSAO);

    if (res.data.length > 0) {
      const transmissao = res.data.map((transmissao) => {
        return {
          CodTransmissao: transmissao.id,
          Transmissao: transmissao.name,
        };
      });
      return transmissao;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getVersaoEurotax(
  marca,
  modelo,
  ano,
  combustivel,
  potencia,
  cilindrada,
  portas,
  transmissao
) {
  try {
    const API_URL_GETVERSAO = {
      method: "GET",
      url: `${global.ApiLinkCatalogo}/v1/easydata/eurotax/GetVersions/`,
      params: {
        marca: marca,
        modelo: modelo,
        ano: ano,
        combustivel: combustivel,
        potencia: potencia,
        cilindrada: cilindrada,
        portas: portas,
        transmissao: transmissao,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API_API },
    };
    const res = await axios.request(API_URL_GETVERSAO);

    if (res.data.length > 0) {
      const versoes = res.data.map((versao) => {
        return {
          CodVersao: versao.id,
          Versao: versao.name,
        };
      });
      return versoes;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function GetDataByVersionEurotax(versao) {
  try {
    const API_URL_GETDATABYVERSION = {
      method: "GET",
      url: `${global.ApiLinkCatalogo}/v1/easydata/eurotax/GetDataByVersion/`,
      params: {
        versao: versao,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API_API },
    };

    const res = await axios.request(API_URL_GETDATABYVERSION);

    return res.data
      ? {
          Lugares: {
            CodLugar: res.data.Lugares.id,
            Lugar: res.data.Lugares.desc,
          },
          Extras: res.data.Extras,
        }
      : 0;
  } catch (error) {
    console.error("error", error);
  }
}

async function GetMatSearchEurotax(matricula) {
  try {
    const API_URL_GETMATSEARCH = {
      method: "GET",
      url: `${
        global.ApiLinkCatalogo
      }/v1/easydata/eurotax/GetMatSearch/${matricula.replaceAll("-", "")}`,
      headers: { token: process.env.REACT_APP_TOKEN_API_API },
    };

    const res = await axios.request(API_URL_GETMATSEARCH);

    if (res.status === 404) {
      return [];
    } else {
      return res.data;
    }
  } catch (error) {
    console.error("error", error);
    return [];
  }
}

async function sendDataEasybuy(dados, infoJson) {
  try {
    const API_URL_INSERT_SENDDATAEASYBUY = {
      method: "POST",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/SendDataEasybuy/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: {
        "Content-Type": "application/json",
        token: process.env.REACT_APP_TOKEN_API,
      },
      data: dados,
    };

    return await axios
      .request(API_URL_INSERT_SENDDATAEASYBUY)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response.status;
      });
  } catch (error) {
    console.error("error", error);
  }
}

export {
  getAllDistritos,
  getAllConcelhos,
  getAllMarcas,
  getAllModelos,
  getAllCombustivel,
  getAllPortas,
  getAllTransmissao,
  getAllLugares,
  getAllCores,
  GetEquipamentos,
  getCombustivelEurotax,
  getExtrasViaturas,
  getPotenciaEurotax,
  getCilindradaEurotax,
  getPortasEurotax,
  getTransmissaoEurotax,
  getVersaoEurotax,
  GetDataByVersionEurotax,
  GetMatSearchEurotax,
  sendDataEasybuy,
  getModeloEurotax,
};
