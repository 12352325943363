import React, { useEffect, useState } from "react";
import { renderEmail } from "react-html-email";
import * as apiEmail from "../../../../api/apiEmail";
import * as apiCarros from "../../../../api/apiCarros";
import useScriptRecaptcha from "../../../hooks/useScriptRecaptcha";
import { Formik } from "formik";
import ValuesSchema from "./validations/valuesSchema";
import initialValues from "./validations/initialValues";

import { useNavigate } from "react-router";

import EmailDestinatarioEasybuy from "./EmailDestinatarioEasybuy";
import EmailRemetenteEasybuy from "./EmailRemetenteEasybuy";
import FormEasyBuy from "./Form/FormEasyBuy";

export default function FormikEasyBuy({ allinfo, infoJson, allinfoText }) {
  const history = useNavigate();

  const [camposEmail, setCamposEmail] = useState({
    assunto: `Pedido de Avaliação de Veiculo - ${infoJson.Stand.Nome}`,
    user: `${process.env.REACT_APP_EMAIL_USERNAME}`,
    pass: `${process.env.REACT_APP_EMAIL_PASSWORD}`,
    emailCliente: "",
    corpoCliente: "",
    emailEmpresa: "",
    corpoEmpresa: "",
    nomeEmpresa: `${infoJson.Stand.Nome}`,
    "g-recaptcha-response": "",
    SECRET_KEY: process.env.REACT_APP_RECAPCHA_SECRETKEY,
  });

  useScriptRecaptcha();

  useEffect(() => {
    camposEmail["emailEmpresa"] = allinfo.Email;
  }, [allinfo, camposEmail, infoJson]);

  const handleSubmit = (values) => {
    camposEmail["emailCliente"] = values.email;

    setCamposEmail(camposEmail);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPCHA_SITEKEY, { action: "submit" })
        .then((token) => {
          camposEmail["g-recaptcha-response"] = token;
          apiCarros.sendDataEasybuy(values, infoJson).then((item) => {
            if (item.status === 200) {
              const emailHTMLDestinatario = renderEmail(
                <EmailDestinatarioEasybuy
                  campos={values}
                  infoJson={infoJson}
                  camposEmail={camposEmail}
                  allinfo={allinfo}
                  data={item.data}
                />
              );
              const emailHTMLRemetente = renderEmail(
                <EmailRemetenteEasybuy
                  campos={values}
                  infoJson={infoJson}
                  allinfo={allinfo}
                  camposEmail={camposEmail}
                  data={item.data}
                  allinfoText={allinfoText}
                />
              );

              camposEmail["corpoCliente"] = emailHTMLRemetente;
              camposEmail["corpoEmpresa"] = emailHTMLDestinatario;

              apiEmail.send2Mail(camposEmail, infoJson).then((error) => {
                if (error.status === 201) {
                  history("/obrigado/");
                } else {
                  history("/erro/");
                }
              });
            } else {
              history("/erro/");
            }
          });
        });
    });
  };

  return (
    <>
      <Formik
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        validationSchema={ValuesSchema}
      >
        {({
          handleSubmit,
          touched,
          getFieldProps,
          setFieldValue,
          values,
          errors,
        }) => {
          return (
            <>
              <FormEasyBuy
                handleSubmit={handleSubmit}
                touched={touched}
                getFieldProps={getFieldProps}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                infoJson={infoJson}
                allinfoText={allinfoText}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
}
