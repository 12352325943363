import React from "react";
import { Form } from "react-bootstrap";

export default function EasybuyMes({ errors, handleChange, touched }) {
  const Month = [
    { value: "1", label: "Janeiro" },
    { value: "2", label: "Fevereiro" },
    { value: "3", label: "Março" },
    { value: "4", label: "Abril" },
    { value: "5", label: "Maio" },
    { value: "6", label: "Junho" },
    { value: "7", label: "Julho" },
    { value: "8", label: "Agosto" },
    { value: "9", label: "Setembro" },
    { value: "10", label: "Outubro" },
    { value: "11", label: "Novembro" },
    { value: "12", label: "Dezembro" },
  ];

  return (
    <>
      <Form.Select
        {...handleChange}
        isInvalid={!!errors.mes && touched.mes}
        isValid={handleChange.value !== ""}
      >
        <option value="">Selecione</option>
        {Month.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">{errors.mes}</Form.Control.Feedback>
    </>
  );
}
