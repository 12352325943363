const initialValues = {
  matricula: "",
  ano: "",
  mes: "",
  marca: {
    nome: "",
    id: "",
  },
  modelo: {
    nome: "",
    id: "",
  },
  combustivel: {
    nome: "",
    id: "",
  },
  potencia: "",
  cilindrada: "",
  porta: {
    nome: "",
    id: "",
  },
  transmissao: {
    nome: "",
    id: "",
  },
  versao: {
    nome: "",
    id: "",
  },
  mostrar: false,
  km: "",
  lugar: {
    nome: "",
    id: "",
  },
  cor: {
    nome: "",
    id: "",
  },
  metalizado: 0,
  extras: [],
  historico: {
    primeiroDono: "",
    livroRevisao: "",
    fumador: "",
    importado: "",
    acidentes: "",
    segundaChave: "",
  },
  pneus: {
    dianteiros: "",
    traseiros: "",
  },
  danos: [],
  textDanos: "",

  nome: "",
  email: "",
  telemovel: "",
  distrito: {
    nome: "",
    id: "",
  },
  concelho: {
    nome: "",
    id: "",
  },
  valorPretende: "",
  motivoVenda: "",

  ficheiro: [],
  terms: false,
};

export default initialValues;
