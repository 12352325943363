import React, { useEffect } from "react";
import { useLocation } from "react-router";
import Banners from "./banner/Banners";
import { createMetaTags } from "../../helpers/tratarInfo";
import * as apiJson from "../../../api/apiJson";
import SectionList from "./SectionList";
import css from "./home.module.css";

import PopUp from "./popup/PopUp";
import VideoBanner from "./banner/VideoBanner";

export default function Home({ infoJson, setMetaTags, allinfo, allinfoText }) {
  const location = useLocation();

  useEffect(() => {
    const arr = [
      "favicon",
      "favicon1",
      "favicon2",
      "favicon3",
      "favicon4",
      "favicon5",
      "favicon6",
    ];

    arr.forEach((item) => {
      const fav = document.getElementById(item);
      fav.href = "/assets/img/fav.png";
    });
  }, []);

  useEffect(() => {
    let Morada;
    if (infoJson.Stand.Distrito === infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Distrito;
    }
    if (infoJson.Stand.Distrito !== infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Concelho + ", " + infoJson.Stand.Distrito;
    }

    const description = `${infoJson.Stand.Nome}  compramos o seu carro a dinheiro. Coloque o seu carro para avaliação e receba uma resposta em 48h.`;

    const tags = ["compramos", "carros", "venda", "hora", "dinheiro", "oferta"];

    const title = `${infoJson.Stand.Nome} - Compramos o seu Carro em  ${Morada}`;

    const metatags = createMetaTags(
      infoJson.Stand.Nome,
      "",
      tags,
      description,
      Morada,
      infoJson.Stand.Website,
      title
    );

    apiJson.createMetaTag({
      url: location.pathname,
      title: title,
      description: description,
      thumbnail: infoJson.Stand.Website + "/assets/img/logo.webp",
    });

    setMetaTags(metatags);
  }, [
    infoJson.Stand.Concelho,
    infoJson.Stand.Distrito,
    infoJson.Stand.Nome,
    infoJson.Stand.Website,
    setMetaTags,
    location.pathname,
  ]);

  return (
    <>
      {infoJson.Stand.PopUp.Existe && <PopUp infoJson={infoJson} />}
      {infoJson.Banner.Existe && <Banners infoJson={infoJson} />}
      {infoJson.Banner.Video.Existe && <VideoBanner infoJson={infoJson} />}

      <section
        className={`${infoJson.Banner.Interior ? css.BannerInt : ""}`}
        style={{
          backgroundImage: infoJson.Banner.Interior
            ? "url(/assets/img/backg.webp)"
            : "none",
        }}
      >
        <SectionList
          infoJson={infoJson}
          allinfo={allinfo}
          allinfoText={allinfoText}
        />
      </section>
    </>
  );
}
