import { getIn } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import * as apiCarros from "../../../../../../api/apiCarros";

export default function EasybuyCombustivel({
  errors,
  handleChange,
  setFieldValue,
  touched,
  infoJson,
  values,
  allVersoesSelected,
}) {
  const [allCombustivel, setAllCombustivel] = useState([]);

  useEffect(() => {
    const getCombustivel = async () => {
      const combustiveisEurotax = await apiCarros.getCombustivelEurotax(
        values.marca.id,
        values.modelo.id,
        values.ano
      );

      if (combustiveisEurotax.length !== 0) {
        setAllCombustivel([
          ...combustiveisEurotax,
          {
            CodCombustivel: "Outra Opção",
            Combustivel: "Outra Opção",
          },
        ]);
        if (combustiveisEurotax.length === 1) {
          setFieldValue(
            "combustivel.id",
            combustiveisEurotax[0].CodCombustivel
          );
          setFieldValue("combustivel.nome", combustiveisEurotax[0].Combustivel);
        }
      } else {
        const combustiveis = await apiCarros.getAllCombustivel(infoJson);
        setAllCombustivel(combustiveis);
      }
    };
    getCombustivel();
  }, [infoJson, values.marca.id, values.modelo.id, values.ano, setFieldValue]);

  const onChangeCombustivel = async (event) => {
    event.persist();

    if (event.target.value === "Outra Opção") {
      const combustiveis = await apiCarros.getAllCombustivel(infoJson);
      setAllCombustivel(combustiveis);
      setFieldValue("combustivel.id", "");
      setFieldValue("combustivel.nome", "");
    }
  };

  useEffect(() => {
    if (allVersoesSelected.length !== 0) {
      setFieldValue("combustivel.id", allVersoesSelected.Cod_combustivel);
      setFieldValue("combustivel.nome", allVersoesSelected.Combustivel);
    }
  }, [allVersoesSelected, setFieldValue]);

  const onChangeReset = () => {
    setFieldValue("potencia", "");
    setFieldValue("cilindrada", "");
    setFieldValue("porta.nome", "");
    setFieldValue("porta.id", "");
    setFieldValue("transmissao.nome", "");
    setFieldValue("transmissao.id", "");
    setFieldValue("versao.nome", "");
    setFieldValue("versao.id", "");
    setFieldValue("lugar.nome", "");
    setFieldValue("lugar.id", "");
  };

  return (
    <>
      <Form.Select
        name={handleChange.name}
        onBlur={handleChange.onBlur}
        onChange={(e) => {
          handleChange.onChange(e);
          onChangeCombustivel(e);
          onChangeReset();
          setFieldValue(
            "combustivel.nome",
            e.target[e.target.selectedIndex].getAttribute("data-value")
          );
        }}
        isInvalid={
          Boolean(getIn(errors, "combustivel.id")) &&
          Boolean(getIn(touched, "combustivel.id"))
        }
        isValid={handleChange.value !== ""}
        disabled={values.modelo.id !== "" ? false : true}
        value={handleChange.value}
      >
        <option value="" data-value="">
          Selecione
        </option>
        {allCombustivel.map((combustivel) => {
          const { CodCombustivel, Combustivel } = combustivel;
          return (
            <option
              key={CodCombustivel}
              value={CodCombustivel}
              data-value={Combustivel}
            >
              {Combustivel}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {getIn(errors, "combustivel.id")}
      </Form.Control.Feedback>
    </>
  );
}
