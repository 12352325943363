import React from "react";
import check_on from "../../../../../../assets/icons/easybuy/check_on.svg";
import check_off from "../../../../../../assets/icons/easybuy/check.svg";
import unckeck_on from "../../../../../../assets/icons/easybuy/unckeck_on.svg";
import unckeck_off from "../../../../../../assets/icons/easybuy/unckeck.svg";
import css from "./easybuycores.module.css";
import { Col } from "react-bootstrap";

export const BtnSimNao = ({ checked, children, onClick, id }) => {
  return (
    <div
      className={`${css.radio} ${css.padding} ${css.rigth} ${
        checked ? css.activo : ""
      }`}
      onClick={onClick}
    >
      {children}
      {id === 1 ? (
        <img src={checked ? check_on : check_off} alt="Sim" />
      ) : (
        <img src={checked ? unckeck_on : unckeck_off} alt="Não" />
      )}
    </div>
  );
};

export function EasybuyMetalizado({ setFieldValue, values }) {
  return (
    <Col >
      <span className={css.label}>Metalizada</span>

      <BtnSimNao
        checked={values.metalizado === 1}
        onClick={() => {
          setFieldValue("metalizado", 1);
        }}
        id={1}
      >
        Sim
      </BtnSimNao>
      <BtnSimNao
        checked={values.metalizado === 0}
        onClick={() => {
          setFieldValue("metalizado", 0);
        }}
        id={2}
      >
        Nao
      </BtnSimNao>
    </Col>
  );
}
