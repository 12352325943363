export const ApiLink = "https://ws.easydata.pt";
//export const ApiLink = "http://192.168.0.153:8090";
//export const ApiLink = "http://192.168.1.67:8090";

export const ApiLinkCatalogo = "https://catalogo.easydata.pt";
//export const ApiLinkCatalogo = "http://192.168.0.70:3000";

/* Keys FileStack*/

export const PickerOptions = {
  maxSize: 10 * 1024 * 1024,
  maxFiles: 12,
  fromSources: ["local_file_system"],
  accept: [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/bmp",
    "image/gif",
    "application/pdf",
  ],
};
