import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import css from "../header.module.css";
import { Link } from "react-router-dom";
import { ReactComponent as Contact } from "../../../../assets/icons/menu/contact.svg";
import { ReactComponent as Emp } from "../../../../assets/icons/menu/emp.svg";
import { ReactComponent as Perg } from "../../../../assets/icons/menu/serv_consult.svg";
import { ReactComponent as Func } from "../../../../assets/icons/menu/funciona.svg";
import { ReactComponent as SvgTestemunhos } from "../../../../assets/icons/servicos/svg_testemunhos.svg";

import CostumNavLink from "./CostumNavLink";
import TradutorHome from "./TradutorHome";

export default function NavBarCollapse({ infoJson, allinfoText }) {
  return (
    <>
      <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
        <Nav className="mr-auto">
          {infoJson.QuemSomos.existe && (
            <CostumNavLink
              className={css.padding}
              as={Link}
              to={
                allinfoText.QuemSomos && allinfoText.QuemSomos.Url !== ""
                  ? allinfoText.QuemSomos.Url
                  : infoJson.QuemSomos.url
              }
            >
              <Emp className=" p-0 mb-2 " height={`27px`} />

              {allinfoText.QuemSomos && allinfoText.QuemSomos.Titulo !== ""
                ? allinfoText.QuemSomos.Titulo
                : infoJson.QuemSomos.titulo}
            </CostumNavLink>
          )}
          {infoJson.PerguntasFrequentes.existe && (
            <CostumNavLink
              className={css.padding}
              as={Link}
              to={
                allinfoText.PerguntasFrequentes &&
                allinfoText.PerguntasFrequentes.Url !== ""
                  ? allinfoText.PerguntasFrequentes.Url
                  : infoJson.PerguntasFrequentes.url
              }
            >
              <Perg className=" p-0 mb-2 " height={`27px`} />

              {allinfoText.PerguntasFrequentes &&
              allinfoText.PerguntasFrequentes.Titulo !== ""
                ? allinfoText.PerguntasFrequentes.Titulo
                : infoJson.PerguntasFrequentes.titulo}
            </CostumNavLink>
          )}
          {infoJson.ComoFunciona.existe && (
            <CostumNavLink
              className={css.padding}
              as={Link}
              to={
                allinfoText.ComoFunciona && allinfoText.ComoFunciona.Url !== ""
                  ? allinfoText.ComoFunciona.Url
                  : infoJson.ComoFunciona.url
              }
            >
              <Func className=" p-0 mb-2 " height={`27px`} />

              {allinfoText.ComoFunciona &&
              allinfoText.ComoFunciona.Titulo !== ""
                ? allinfoText.ComoFunciona.Titulo
                : infoJson.ComoFunciona.titulo}
            </CostumNavLink>
          )}
          {infoJson.Testemunhos.existe && (
            <CostumNavLink
              className={css.padding}
              as={Link}
              to={
                allinfoText.Testemunhos && allinfoText.Testemunhos.Url !== ""
                  ? allinfoText.Testemunhos.Url
                  : infoJson.Testemunhos.url
              }
            >
              <SvgTestemunhos className=" p-0 mb-2 " height={`27px`} />

              {allinfoText.Testemunhos && allinfoText.Testemunhos.Titulo !== ""
                ? allinfoText.Testemunhos.Titulo
                : infoJson.Testemunhos.titulo}
            </CostumNavLink>
          )}
          {infoJson.Contactos.existe && (
            <CostumNavLink
              className={css.padding}
              as={Link}
              to={
                allinfoText.Contactos && allinfoText.Contactos.Url !== ""
                  ? allinfoText.Contactos.Url
                  : infoJson.Contactos.url
              }
            >
              <Contact className=" p-0 mb-2 " height={`27px`} />

              {allinfoText.Contactos && allinfoText.Contactos.Titulo !== ""
                ? allinfoText.Contactos.Titulo
                : infoJson.Contactos.titulo}
            </CostumNavLink>
          )}

          {infoJson.Layout.Tradutor && <TradutorHome />}
        </Nav>
      </Navbar.Collapse>
    </>
  );
}
