function tratarDataManutencao(Data) {
  try {
    //Trata de data de Intervenção

    let textData;
    let mesTxt;
    let dataExtenso = Data.split(" ");
    dataExtenso = dataExtenso[0].split("/");

    switch (dataExtenso[1]) {
      case "01":
        mesTxt = "Janeiro";
        break;
      case "02":
        mesTxt = "Fevereiro";
        break;
      case "03":
        mesTxt = "Março";
        break;
      case "04":
        mesTxt = "Abril";
        break;
      case "05":
        mesTxt = "Maio";
        break;
      case "06":
        mesTxt = "Junho";
        break;
      case "07":
        mesTxt = "Julho";
        break;
      case "08":
        mesTxt = "Agosto";
        break;
      case "09":
        mesTxt = "Setembro";
        break;
      case "10":
        mesTxt = "Outubro";
        break;
      case "11":
        mesTxt = "Novembro";
        break;
      case "12":
        mesTxt = "Dezembro";
        break;
      default:
        mesTxt = "";
        break;
    }

    textData = dataExtenso[0] + " " + mesTxt + " de " + dataExtenso[2];
    return textData;
  } catch (error) {
    console.log("error", error);
  }
}

export { tratarDataManutencao };
