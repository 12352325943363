import { getIn } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import * as apiCarros from "../../../../../../api/apiCarros";

export default function EasybuyTransmissao({
  errors,
  handleChange,
  setFieldValue,
  touched,
  infoJson,
  values,
  allVersoesSelected,
}) {
  const [allTransmissao, setAllTransmissao] = useState([]);

  useEffect(() => {
    const getTransmissao = async () => {
      const transmissaoEurotax = await apiCarros.getTransmissaoEurotax(
        values.marca.id,
        values.modelo.id,
        values.ano,
        values.combustivel.id,
        values.potencia,
        values.cilindrada,
        values.porta.id
      );

      if (transmissaoEurotax.length !== 0) {
        setAllTransmissao([
          ...transmissaoEurotax,
          {
            CodTransmissao: "Outra Opção",
            Transmissao: "Outra Opção",
          },
        ]);
        if (transmissaoEurotax.length === 1) {
          setFieldValue("transmissao.id", transmissaoEurotax[0].CodTransmissao);
          setFieldValue("transmissao.nome", transmissaoEurotax[0].Transmissao);
        }
      } else {
        const transmissao = await apiCarros.getAllTransmissao(infoJson);

        setAllTransmissao(transmissao);
      }
    };
    getTransmissao();
  }, [
    infoJson,
    values.marca.id,
    values.modelo.id,
    values.ano,
    values.cilindrada,
    values.combustivel.id,
    values.potencia,
    values.porta.id,
    setFieldValue,
  ]);

  const onChangeTransmissao = async (event) => {
    event.persist();

    if (event.target.value === "Outra Opção") {
      const Transmissao = await apiCarros.getAllTransmissao(infoJson);
      setAllTransmissao(Transmissao);
      setFieldValue("transmissao.id", "");
      setFieldValue("transmissao.nome", "");
    }
  };

  useEffect(() => {
    if (allVersoesSelected.length !== 0) {
      setFieldValue("transmissao.id", allVersoesSelected.Cod_transmissao);
      setFieldValue("transmissao.nome", allVersoesSelected.Transmissao);
    }
  }, [allVersoesSelected, setFieldValue]);

  const onChangeReset = () => {
    setFieldValue("versao.nome", "");
    setFieldValue("versao.id", "");
    setFieldValue("lugar.nome", "");
    setFieldValue("lugar.id", "");
  };

  return (
    <>
      <Form.Select
        name={handleChange.name}
        onBlur={handleChange.onBlur}
        onChange={(e) => {
          handleChange.onChange(e);
          onChangeTransmissao(e);
          onChangeReset();
          setFieldValue(
            "transmissao.nome",
            e.target[e.target.selectedIndex].getAttribute("data-value")
          );
        }}
        isInvalid={
          Boolean(getIn(errors, "transmissao.id")) &&
          Boolean(getIn(touched, "transmissao.id"))
        }
        isValid={handleChange.value !== ""}
        disabled={values.porta.id !== "" ? false : true}
        value={handleChange.value}
      >
        <option value="" data-value="">
          Selecione
        </option>
        {allTransmissao.map((transmissao) => {
          const { CodTransmissao, Transmissao } = transmissao;
          return (
            <option
              key={CodTransmissao}
              value={CodTransmissao}
              data-value={Transmissao}
            >
              {Transmissao}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {getIn(errors, "transmissao.id")}
      </Form.Control.Feedback>
    </>
  );
}
