import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import * as apiCarros from "../../../../../../api/apiCarros";

export default function EasybuyPotencia({
  errors,
  handleChange,
  setFieldValue,
  touched,
  infoJson,
  values,
  allVersoesSelected,
}) {
  const [allPotencia, setAllPotencia] = useState([]);

  useEffect(() => {
    const getPotencia = async () => {
      const potenciaEurotax = await apiCarros.getPotenciaEurotax(
        values.marca.id,
        values.modelo.id,
        values.ano,
        values.combustivel.id
      );

      if (potenciaEurotax.length !== 0) {
        setAllPotencia([
          ...potenciaEurotax,
          {
            Potencia: "Outra Opção",
          },
        ]);
        if (potenciaEurotax.length === 1) {
          setFieldValue("potencia", potenciaEurotax[0].Potencia);
        }
      } else {
        setAllPotencia([]);
      }
    };
    getPotencia();
  }, [
    infoJson,
    values.marca.id,
    values.modelo.id,
    values.ano,
    values.combustivel.id,
    setFieldValue,
  ]);

  const onChangePotencia = async (event) => {
    event.persist();

    if (event.target.value === "Outra Opção") {
      setAllPotencia([]);
      setFieldValue("potencia", "");
    }
  };

  useEffect(() => {
    if (allVersoesSelected.length !== 0) {
      setFieldValue("potencia", allVersoesSelected.Potencia);
    }
  }, [allVersoesSelected, setFieldValue]);

  const onChangeReset = () => {
    setFieldValue("cilindrada", "");
    setFieldValue("porta.nome", "");
    setFieldValue("porta.id", "");
    setFieldValue("transmissao.nome", "");
    setFieldValue("transmissao.id", "");
    setFieldValue("versao.nome", "");
    setFieldValue("versao.id", "");
    setFieldValue("lugar.nome", "");
    setFieldValue("lugar.id", "");
  };

  return (
    <>
      {allPotencia.length !== 0 ? (
        <>
          <Form.Select
            name={handleChange.name}
            onBlur={handleChange.onBlur}
            onChange={(e) => {
              handleChange.onChange(e);
              onChangePotencia(e);
              onChangeReset();
            }}
            isInvalid={!!errors.potencia && touched.potencia}
            isValid={handleChange.value !== ""}
            disabled={values.combustivel.id !== "" ? false : true}
            value={handleChange.value}
          >
            <option value="">Selecione</option>
            {allPotencia.map((item, index) => {
              const { Potencia } = item;
              return (
                <option key={index} value={Potencia}>
                  {Potencia} {Potencia === "Outra Opção" ? "" : "Cv"}
                </option>
              );
            })}
          </Form.Select>
        </>
      ) : (
        <>
          <Form.Control
            disabled={values.combustivel.id !== "" ? false : true}
            placeholder="Escreva aqui"
            {...handleChange}
            isInvalid={!!errors.potencia && touched.potencia}
            isValid={handleChange.value !== ""}
            value={handleChange.value}
            type="number"
          />
        </>
      )}

      <Form.Control.Feedback type="invalid">
        {errors.potencia}
      </Form.Control.Feedback>
    </>
  );
}
