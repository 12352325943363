import React from "react";
import { Col } from "react-bootstrap";

import dd from "../../../../../../assets/icons/easybuy/danos/dd.jpg";
import frente_tras from "../../../../../../assets/icons/easybuy/danos/frente_tras.jpg";
import esq from "../../../../../../assets/icons/easybuy/danos/esq.jpg";
import dir from "../../../../../../assets/icons/easybuy/danos/dir.jpg";
import cima from "../../../../../../assets/icons/easybuy/danos/cima.jpg";
import css from "./easybuydanos.module.css";

export default function EasybuyDanos({ setFieldValue, values }) {
  const init = [
    {
      name: 1,
      value: "A",
    },
    {
      name: 2,
      value: "B",
    },
    {
      name: 3,
      value: "C",
    },
    {
      name: 4,
      value: "D",
    },
    {
      name: 5,
      value: "E",
    },
    {
      name: 6,
      value: "F",
    },
    {
      name: 7,
      value: "G",
    },
    {
      name: 8,
      value: "H",
    },
    {
      name: 9,
      value: "I",
    },
    {
      name: 10,
      value: "J",
    },
    {
      name: 11,
      value: "K",
    },
    {
      name: 12,
      value: "L",
    },
    {
      name: 13,
      value: "M",
    },
    {
      name: 14,
      value: "N",
    },
    {
      name: 15,
      value: "O",
    },
    {
      name: 16,
      value: "P",
    },
    {
      name: 17,
      value: "Q",
    },

    {
      name: 18,
      value: "R",
    },
    {
      name: 19,
      value: "S",
    },
    {
      name: 20,
      value: "T",
    },

    {
      name: 21,
      value: "U",
    },
    {
      name: 22,
      value: "V",
    },
    {
      name: 23,
      value: "W",
    },

    {
      name: 24,
      value: "X",
    },
    {
      name: 25,
      value: "Y",
    },
    {
      name: 26,
      value: "Z",
    },
    {
      name: 27,
      value: "1",
    },
  ];

  return (
    <Col className={`${css.flex} mb-3`}>
      <div className={`${css.img}`}>
        <img className="hidden-xs" src={dd} alt={"danos total"} />
        <img
          className="hidden-sm hidden-md hidden-lg"
          alt="frente_tras"
          src={frente_tras}
        />
        <img className="hidden-sm hidden-md hidden-lg" alt="esq" src={esq} />
        <img className="hidden-sm hidden-md hidden-lg" alt="dir" src={dir} />
        <img className="hidden-sm hidden-md hidden-lg" alt="cima" src={cima} />
      </div>
      {init.map((item, index) => {
        const handleChange = (event) => {
          if (values.danos.includes(event)) {
            const arr = values.danos.filter((item) => {
              return item !== event;
            });
            setFieldValue("danos", arr);
          } else {
            const arr = [...values.danos, event];
            setFieldValue("danos", arr);
          }
        };

        return (
          <div
            key={index}
            className={`${css.quare} ${
              values.danos.includes(item.value) ? css.active : ""
            }`}
          >
            <label
              htmlFor={`danos${item.name}`}
              onClick={() => handleChange(item.value)}
            >
              {item.name}
            </label>
          </div>
        );
      })}
    </Col>
  );
}
