import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Spacer from "../home/Spacer";
import * as apiJson from "../../../api/apiJson";
import { createMetaTags } from "../../helpers/tratarInfo";
import css from "../home/home.module.css";

export default function Perguntas({ infoJson, setMetaTags, allinfoText }) {
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
  });

  useEffect(() => {
    const arr = [
      "favicon",
      "favicon1",
      "favicon2",
      "favicon3",
      "favicon4",
      "favicon5",
      "favicon6",
    ];

    arr.forEach((item) => {
      const fav = document.getElementById(item);
      fav.href = "/assets/img/fav.png";
    });
  }, []);

  useEffect(() => {
    allinfoText.PerguntasFrequentes &&
      setAlltexto(allinfoText.PerguntasFrequentes);
  }, [allinfoText]);

  useEffect(() => {
    let Morada;
    if (infoJson.Stand.Distrito === infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Distrito;
    }
    if (infoJson.Stand.Distrito !== infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Concelho + ", " + infoJson.Stand.Distrito;
    }
    const description =
      `Disponibiliza esta página para as perguntas frequentes sobre como nos pode vender o seu carro.` +
      `  Visite-nos em ${infoJson.Stand.Concelho}`;

    const tags = [
      `Stand usados ${infoJson.Stand.Concelho}`,
      infoJson.Stand.Distrito,
      "Perguntas Frequentes",
      "Também compram?",
      `usados ${infoJson.Stand.Nome}`,
      "Compramos a sua viatura",
      "compramos",
      "carros",
      "venda",
      "hora",
      "dinheiro",
      "oferta",
      "perguntas frequentes",
      "FAQ",
    ];

    const title = `${
      alltexto.Titulo !== ""
        ? alltexto.Titulo
        : infoJson.PerguntasFrequentes.titulo
    } - ${infoJson.Stand.Nome} em ${infoJson.Stand.Concelho}`;

    const metatags = createMetaTags(
      infoJson.Stand.Nome,
      "",
      tags,
      description,
      Morada,
      infoJson.Stand.Website,
      title
    );
    apiJson.createMetaTag({
      url:
        allinfoText.PerguntasFrequentes &&
        allinfoText.PerguntasFrequentes.Url !== ""
          ? allinfoText.PerguntasFrequentes.Url
          : infoJson.PerguntasFrequentes.url,
      title: title,
      description: description,
      thumbnail: infoJson.Stand.Website + "/assets/img/logo.webp",
    });
    setMetaTags(metatags);
  }, [infoJson, setMetaTags, alltexto, allinfoText.PerguntasFrequentes]);

  return (
    <>
      <section>
        <Spacer height="90px" />
        <Spacer height="40px" clasName="col-12" />
        <Container>
          <Row>
            <h1 xs={12} className={css.listagem_titulo}>
              {alltexto.Titulo !== ""
                ? alltexto.Titulo
                : infoJson.PerguntasFrequentes.titulo}
            </h1>

            {alltexto.SubTitulo && (
              <h2 xs={12} className={`${css.listagem_subtitulo} titulo-page`}>
                {alltexto.SubTitulo}
              </h2>
            )}
            <Spacer height="15px" clasName="col-12" />
            <Spacer height="20px" clasName="col-12" />

            {alltexto.Zona ? (
              <p
                xs={12}
                className={`${css.paragrafo}`}
                dangerouslySetInnerHTML={{ __html: alltexto.Zona }}
              />
            ) : (
              <>
                <p>
                  <font size="3">
                    <strong>
                      Se concordar com a vossa oferta, como deverei proceder
                      para finalizar a venda?
                    </strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    Deve agendar uma deslocação às nossas instalações, em Braga,
                    para finalizar o processo.{" "}
                  </font>
                </p>
                <p>
                  <font size="3">
                    <strong>
                      Como é efetuado o pagamento da minha viatura?
                    </strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    O pagamento da viatura será feito por transferência
                    bancária.
                  </font>
                </p>
                <p>
                  <font size="3">
                    <strong>A viatura é transferida de nome?</strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    Sim, o processo de transferência da viatura será tratado por
                    nós. Relativamente à Via Verde (se aplicável) e ao seguro
                    auto da viatura, é da sua responsabilidade proceder à
                    alteração da matrícula associada ao identificador e à
                    anulação do seguro.
                  </font>
                </p>
                <p>
                  <strong>A viatura está financiada. Também compram?</strong>
                </p>
                <p>
                  <font size="3">
                    Sim, também compramos viaturas que se encontrem financiadas.
                    <br></br>
                    Mediante autorização sua apuramos, junto da Financeira em
                    causa, o valor em falta e procedemos à liquidação do mesmo.
                    O valor a receber é calculado por encontro de contas entre o
                    valor da nossa oferta e o valor de liquidação do contrato de
                    crédito.
                  </font>
                </p>
                <p>
                  <font size="3">
                    <strong>Não encontro a minha viatura no site…</strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    Pode acontecer. Nesse caso deve escolher a versão que se
                    enquadre mais à sua viatura e fazer referência a essa
                    discrepância no campo de observações (Passo 2). Pode,
                    também, entrar em contacto connosco para ver se, em
                    conjunto, conseguimos proceder à identificação.
                  </font>
                </p>
                <p>
                  <font size="3">
                    <strong>
                      A minha viatura não tem matrícula portuguesa. Compram?
                    </strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    Não, só compramos viaturas registadas em Portugal.
                  </font>
                </p>
                <p>
                  <font size="3">
                    <strong>A minha viatura não funciona. Compram?</strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    Não, só compramos viaturas que estejam em condições de
                    circulação.
                  </font>
                </p>
                <p>
                  <font size="3">
                    <strong>
                      Posso vender uma viatura em nome de um familiar ou amigo?
                    </strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    Sim, desde que se faça acompanhar de uma autorização escrita
                    para o efeito ou, no momento da finalização da venda, o
                    familiar ou amigo em causa esteja presente.
                  </font>
                </p>
                <p>
                  <font size="3">
                    <strong>
                      Posso vender uma viatura que está em nome de uma Empresa?
                    </strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    Sim, mediante uma carta da Empresa a mandatá-lo para o
                    efeito.
                  </font>
                </p>
                <p>
                  <font size="3">
                    <strong>
                      A vossa oferta é válida por quanto tempo? É vinculativa?
                    </strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    A nossa oferta é válida por 7 dias e só se torna vinculativa
                    após a inspeção à viatura nas nossas instalações.
                  </font>
                </p>
                <p>
                  <font size="3">
                    <strong>O que é a Inspeção antes de entrega?</strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    Tem como objetivo validar toda a documentação subjacente à
                    venda da viatura e verificar se a mesma condiz com a
                    informação prestada no seu pedido de avaliação. Após esta
                    inspeção, será confirmado com precisão o valor final de
                    aquisição.
                  </font>
                </p>
                <p>
                  <font size="3">
                    <strong>
                      O que devo levar para fazer a venda da minha viatura?
                    </strong>
                  </font>
                </p>
                <p>
                  <font size="3">
                    Livrete (DUA)<br></br>
                    Folha de IPO<br></br>
                    Comprovativo de pagamento do IUC<br></br>
                    2ª Chave<br></br>
                    Manuais e Livro de Revisões<br></br>
                    Roda de Socorro, Triângulo e Ferramenta<br></br>
                    Bilhete de Identidade e cartão de Contribuinte <b>
                      ou
                    </b>{" "}
                    Cartão de Cidadão . (Se a viatura estiver em nome de uma
                    Empresa deverá trazer Modelo Único a vender, assinado e
                    reconhecido com poderes para o ato).<br></br>
                    NIB (Número de Identificação Bancária) para efetuarmos o
                    pagamento.
                  </font>
                </p>
              </>
            )}
          </Row>
        </Container>
        <Spacer height="150px" clasName="col-12" />
      </section>
    </>
  );
}
