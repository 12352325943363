import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import * as apiCarros from "../../../../../../api/apiCarros";

export default function EasybuyCilindrada({
  errors,
  handleChange,
  setFieldValue,
  touched,
  infoJson,
  values,
}) {
  const [allCilindrada, setAllCilindrada] = useState([]);
  
  useEffect(() => {
    const getCilindrada = async () => {
      const CilindradaEurotax = await apiCarros.getCilindradaEurotax(
        values.marca.id,
        values.modelo.id,
        values.ano,
        values.combustivel.id,
        values.potencia
      );

      if (CilindradaEurotax.length !== 0) {
        setAllCilindrada([
          ...CilindradaEurotax,
          {
            Cilindrada: "Outra Opção",
          },
        ]);
        if (CilindradaEurotax.length === 1) {
          setFieldValue("cilindrada", CilindradaEurotax[0].Cilindrada);
        }
      } else {
        setAllCilindrada([]);
      }
    };
    getCilindrada();
  }, [
    infoJson,
    values.marca.id,
    values.modelo.id,
    values.ano,
    values.combustivel.id,
    values.potencia,
    setFieldValue,
  ]);

  const onChangeCilindrada = async (event) => {
    event.persist();

    if (event.target.value === "Outra Opção") {
      setAllCilindrada([]);
      setFieldValue("cilindrada", "");
    }
  };

  const onChangeReset = () => {
  
    setFieldValue("porta.nome", "");
    setFieldValue("porta.id", "");
    setFieldValue("transmissao.nome", "");
    setFieldValue("transmissao.id", "");
    setFieldValue("versao.nome", "");
    setFieldValue("versao.id", "");
    setFieldValue("lugar.nome", "");
    setFieldValue("lugar.id", "");
  };

  return (
    <>
      {allCilindrada.length !== 0 ? (
        <>
          <Form.Select
            name={handleChange.name}
            onBlur={handleChange.onBlur}
            onChange={(e) => {
              handleChange.onChange(e);
              onChangeCilindrada(e);
              onChangeReset();
            }}
            isInvalid={!!errors.cilindrada && touched.cilindrada}
            isValid={handleChange.value !== ""}
            disabled={
              values.potencia !== "" && values.combustivel.id !== 41
                ? false
                : true
            }
            value={handleChange.value}
          >
            <option value="">Selecione</option>
            {allCilindrada.map((item, index) => {
              const { Cilindrada } = item;
              return (
                <option key={index} value={Cilindrada}>
                  {Cilindrada} {Cilindrada === "Outra Opção" ? "" : "cm3"}
                </option>
              );
            })}
          </Form.Select>
        </>
      ) : (
        <>
          <Form.Control
            disabled={values.potencia !== "" ? false : true}
            placeholder="Escreva aqui"
            {...handleChange}
            isInvalid={!!errors.cilindrada && touched.cilindrada}
            isValid={handleChange.value !== ""}
            value={handleChange.value}
            type="number"
          />
        </>
      )}

      <Form.Control.Feedback type="invalid">
        {errors.cilindrada}
      </Form.Control.Feedback>
    </>
  );
}
