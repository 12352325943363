import { getIn } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import * as apiCarros from "../../../../../../api/apiCarros";

export default function EasybuyVersao({
  errors,
  handleChange,
  setFieldValue,
  touched,
  infoJson,
  values,
  allVersoesSelected,
}) {
  const [allVersao, setAllVersao] = useState([]);

  useEffect(() => {
    const getVersao = async () => {
      const VersaoEurotax = await apiCarros.getVersaoEurotax(
        values.marca.id,
        values.modelo.id,
        values.ano,
        values.combustivel.id,
        values.potencia,
        values.cilindrada,
        values.porta.id,
        values.transmissao.id
      );

      if (VersaoEurotax.length !== 0) {
        setAllVersao([
          ...VersaoEurotax,
          {
            CodVersao: "Outra Opção",
            Versao: "Outra Opção",
          },
        ]);
        if (VersaoEurotax.length === 1) {
          setFieldValue("versao.id", VersaoEurotax[0].CodVersao);
          setFieldValue("versao.nome", VersaoEurotax[0].Versao);
        }
      } else {
        setAllVersao([]);
      }
    };
    getVersao();
  }, [
    infoJson,
    values.marca.id,
    values.modelo.id,
    values.ano,
    values.combustivel.id,
    values.potencia,
    values.cilindrada,
    values.porta.id,
    values.transmissao.id,
    setFieldValue,
  ]);

  useEffect(() => {
    if (allVersoesSelected.length !== 0) {
      setFieldValue("versao.id", allVersoesSelected.Cod_versao);
      setFieldValue("versao.nome", allVersoesSelected.Nome_easydata);
    }
  }, [allVersoesSelected, setFieldValue]);

  const onChangeVersao = async (event) => {
    event.persist();

    if (event.target.value === "Outra Opção") {
      setAllVersao([]);
      setFieldValue("versao.id", "");
      setFieldValue("versao.nome", "");
    }
  };

  const onChangeReset = () => {
    setFieldValue("lugar.nome", "");
    setFieldValue("lugar.id", "");
  };

  return (
    <>
      {allVersao.length !== 0 ? (
        <>
          <Form.Select
            name={handleChange.name}
            onBlur={handleChange.onBlur}
            onChange={(e) => {
              handleChange.onChange(e);
              onChangeVersao(e);
              onChangeReset();
              setFieldValue(
                "versao.id",
                e.target[e.target.selectedIndex].getAttribute("data-value")
              );
            }}
            isInvalid={
              Boolean(getIn(errors, "versao.nome")) &&
              Boolean(getIn(touched, "versao.nome"))
            }
            isValid={handleChange.value !== ""}
            disabled={values.transmissao.id !== "" ? false : true}
            value={handleChange.value}
          >
            <option value="">Selecione</option>
            {allVersao.map((item) => {
              const { Versao, CodVersao } = item;
              return (
                <option key={Versao} value={Versao} data-value={CodVersao}>
                  {Versao}
                </option>
              );
            })}
          </Form.Select>
        </>
      ) : (
        <>
          <Form.Control
            disabled={values.transmissao.id !== "" ? false : true}
            placeholder="Escreva aqui"
            {...handleChange}
            isInvalid={
              Boolean(getIn(errors, "versao.nome")) &&
              Boolean(getIn(touched, "versao.nome"))
            }
            isValid={handleChange.value !== ""}
            value={handleChange.value}
            type="text"
          />
        </>
      )}

      <Form.Control.Feedback type="invalid">
        {getIn(errors, "versao.nome")}
      </Form.Control.Feedback>
    </>
  );
}
