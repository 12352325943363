import { getIn } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import * as apiCarros from "../../../../../../api/apiCarros";

export default function GetDataByVersionEasybuyPortas({
  errors,
  handleChange,
  setFieldValue,
  touched,
  infoJson,
  values,
  allVersoesSelected,
}) {
  const [allPortas, setAllPortas] = useState([]);

  useEffect(() => {
    const getPortas = async () => {
      const portasEurotax = await apiCarros.getPortasEurotax(
        values.marca.id,
        values.modelo.id,
        values.ano,
        values.combustivel.id,
        values.potencia,
        values.cilindrada
      );

      if (portasEurotax.length !== 0) {
        setAllPortas([
          ...portasEurotax,
          {
            CodPorta: "Outra Opção",
            Porta: "Outra Opção",
          },
        ]);
        if (portasEurotax.length === 1) {
          setFieldValue("porta.id", portasEurotax[0].CodPorta);
          setFieldValue("porta.nome", portasEurotax[0].Porta);
        }
      } else {
        const portas = await apiCarros.getAllPortas(infoJson);
        setAllPortas(portas);
      }
    };

    getPortas();
  }, [
    infoJson,
    values.marca.id,
    values.modelo.id,
    values.ano,
    values.cilindrada,
    values.combustivel.id,
    values.potencia,
    setFieldValue,
  ]);

  useEffect(() => {
    if (allVersoesSelected.length !== 0) {
      setFieldValue("porta.id", allVersoesSelected.Cod_portas);
      setFieldValue("porta.nome", allVersoesSelected.Portas);
    }
  }, [allVersoesSelected, setFieldValue]);

  const onChangePortas = async (event) => {
    event.persist();

    if (event.target.value === "Outra Opção") {
      const portas = await apiCarros.getAllPortas(infoJson);
      setAllPortas(portas);
      setFieldValue("porta.id", "");
      setFieldValue("porta.nome", "");
    }
  };

  const onChangeReset = () => {
    setFieldValue("transmissao.nome", "");
    setFieldValue("transmissao.id", "");
    setFieldValue("versao.nome", "");
    setFieldValue("versao.id", "");
    setFieldValue("lugar.nome", "");
    setFieldValue("lugar.id", "");
  };

  return (
    <>
      <Form.Select
        name={handleChange.name}
        onBlur={handleChange.onBlur}
        onChange={(e) => {
          handleChange.onChange(e);
          onChangePortas(e);
          onChangeReset();
          setFieldValue(
            "porta.nome",
            e.target[e.target.selectedIndex].getAttribute("data-value")
          );
        }}
        isInvalid={
          Boolean(getIn(errors, "porta.id")) &&
          Boolean(getIn(touched, "porta.id"))
        }
        isValid={handleChange.value !== ""}
        disabled={values.cilindrada !== "" ? false : true}
        value={handleChange.value}
      >
        <option value="" data-value="">
          Selecione
        </option>
        {allPortas.map((porta) => {
          const { CodPorta, Porta } = porta;
          return (
            <option key={CodPorta} value={CodPorta} data-value={Porta}>
              {Porta}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {getIn(errors, "porta.id")}
      </Form.Control.Feedback>
    </>
  );
}
