const EMAIL_LOGO_WIDTH = 314 / 2;
const BODY_BOX_BACKGROUND_COLOR = "#fff";
const EMAIL_BACKGROUND_COLOR = "#ECECEC";
const BODY_FOOTER_COLOR = "#999";
const BODY_BOX_WIDTH = "600px";
const BODY_CONTENT_COLOR = "#222222";
const BODY_CONTENT_LINK_COLOR = "#00A188";
const BODY_CONTENT_FONT_SIZE = 16;
const PADDING_M = "25";
const PADDING_S = "15";
const padding = (top = 0, right = 0, bottom = 0, left = 0) =>
  `${top}px ${right}px ${bottom}px ${left}px`;

const bodyItemStyle = {
  backgroundColor: BODY_BOX_BACKGROUND_COLOR,
};

export const theme = {
  email: {
    backgroundColor: EMAIL_BACKGROUND_COLOR,
    width: "100%",
    padding: 0,
  },
  emailHeader: {
    item: {
      padding: padding(PADDING_M, PADDING_M, 0, PADDING_M),
      backgroundColor: BODY_BOX_BACKGROUND_COLOR,
    },
    logo: {
      width: EMAIL_LOGO_WIDTH,
      height: "auto",
    },
    a: {
      textDecoration: "none",
      color: BODY_CONTENT_COLOR,
    },
    emailHeaderText: {
      color: BODY_CONTENT_LINK_COLOR,
      fontSize: 21,
      fontWeight: 600,
      lineHeight: "21px",
      margin: 0,
    },
    color: {
      color: BODY_CONTENT_LINK_COLOR,
    },
  },
  emailBody: {
    box: {
      align: "center",
      style: {
        width: BODY_BOX_WIDTH,
      },
    },
    line: {
      bg: { backgroundColor: " #999999", height: "2px" },
      style: {
        padding: padding(0, PADDING_M, 0, PADDING_M),
        backgroundColor: BODY_BOX_BACKGROUND_COLOR,
      },
    },
    bodyHeader: {
      item: {
        ...bodyItemStyle,
        ...{
          padding: padding(PADDING_S, PADDING_M, PADDING_S, PADDING_M),
        },
      },
      span: {
        color: BODY_CONTENT_COLOR,
        fontSize: 21,
        lineHeight: "30px",
        margin: 0,
      },
      small: {
        fontSize: 18,
      },
    },
    bodyContent: {
      item: {
        ...bodyItemStyle,
        ...{
          padding: padding(PADDING_S, PADDING_M, PADDING_S, PADDING_M),
        },
      },
      span: {
        fontSize: BODY_CONTENT_FONT_SIZE,
        lineHeight: "22px",
        margin: 0,
      },

      a: {
        color: BODY_CONTENT_LINK_COLOR,
      },
    },
    bodyFooter: {
      item: {
        ...{
          padding: padding(PADDING_S, PADDING_M, PADDING_S, PADDING_M),
          backgroundColor: BODY_BOX_BACKGROUND_COLOR,
        },
      },
      spanNoMargin: {
        color: BODY_CONTENT_COLOR,
        lineHeight: "22px",
        margin: "0",
      },
      spanNoMarginA: {
        color: BODY_CONTENT_COLOR,
        lineHeight: "22px",
        margin: "0",
        textDecoration: "none",
      },
      span: {
        color: BODY_CONTENT_COLOR,
        lineHeight: "22px",
        margin: "5px 0",
      },
      spanDisable: {
        color: BODY_FOOTER_COLOR,
        lineHeight: "22px",
        margin: 0,
      },
      a: {
        color: BODY_CONTENT_LINK_COLOR,
      },
    },
  },
};
