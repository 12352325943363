import React, { useState } from "react";
import { useEffect } from "react";
import check_on from "../../../../../../assets/icons/easybuy/check_on.svg";
import check_off from "../../../../../../assets/icons/easybuy/check.svg";
import * as apiCarros from "../../../../../../api/apiCarros";
import css from "./easybuyextras.module.css";
import { Col, Form, Row } from "react-bootstrap";

const CheckExtras = ({ checked, onClick, children }) => {
  return (
    <div
      className={`${css.radio} ${checked ? css.activo : ""}`}
      onClick={onClick}
    >
      <img src={checked ? check_on : check_off} alt={"check"} />
      {children}
    </div>
  );
};

const SelectExtras = ({ values, opcoes, setFieldValue, nome, Extras }) => {
  const [array, setArray] = useState("");

  const handlerExtras = (e) => {
    const arr = [...e.target.children];
    let options = [];
    arr.forEach((item) => {
      options = [...options, item.value];
    });
    const val = values.extras.filter((x) => !options.includes(x));
    setFieldValue("extras", [...val, e.target.value]);
  };

  useEffect(() => {
    const arr = Extras.filter((item) => {
      return item.nome === nome;
    });

    if (arr.length !== 0) {
      setArray(arr[0].opcao);
    }
  }, [Extras, nome]);

  return (
    <Form.Select
      className={`${css.select}`}
      onChange={(e) => {
        setArray(e.target.value);
        handlerExtras(e);
      }}
      value={array}
      isValid={array !== ""}
      aria-label="Default select example"
    >
      <option value="">Selecione {nome}</option>
      {opcoes.map((item) => {
        return (
          <option key={item.id} value={item.nome}>
            {item.nome}
          </option>
        );
      })}
    </Form.Select>
  );
};

export default function EasybuyExtras({
  values,
  setFieldValue,
  infoJson,
  Extras,
}) {
  const [allEquipamentos, setAllEquipamentos] = useState([]);

  useEffect(() => {
    const getEquipamentos = async () => {
      const equipamentos = await apiCarros.GetEquipamentos(infoJson);
      setAllEquipamentos(equipamentos);
    };
    getEquipamentos();
  }, [infoJson]);

  return (
    <>
      {allEquipamentos.map((item, index) => {
        const { categoria, equipamentos } = item;

        if (categoria === "Auto Caravanas")
          return <React.Fragment key={index + 10000}></React.Fragment>;
        return (
          <Col key={index + 10000} className="mb-3">
            <div className={`${css.titulo}`}>{categoria}</div>

            <Row xs={1} md={2} lg={3} xl={4} xxl={5}>
              {equipamentos.map((itemNew) => {
                const { id, nome, opcoes } = itemNew;
                const handleChange = (event) => {
                  if (values.extras.includes(event)) {
                    const arr = values.extras.filter((item) => {
                      return item !== event;
                    });
                    setFieldValue("extras", arr);
                  } else {
                    const arr = [...values.extras, event];
                    setFieldValue("extras", arr);
                  }
                };

                return (
                  <React.Fragment key={id}>
                    {opcoes === null ? (
                      <>
                        <Col className="mb-3">
                          <CheckExtras
                            checked={values.extras.includes(nome)}
                            onClick={() => handleChange(nome)}
                          >
                            {nome}
                          </CheckExtras>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col className="mb-3">
                          <SelectExtras
                            values={values}
                            opcoes={opcoes}
                            setFieldValue={setFieldValue}
                            nome={nome}
                            Extras={Extras}
                          />
                        </Col>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </Row>
          </Col>
        );
      })}
    </>
  );
}
