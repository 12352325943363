import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import css from "../carrousel.module.css";
import cssAH from "../albumHome/albumHome.module.css";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow/Slider.svg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Col } from "react-bootstrap";
import CardTestemunhos from "../../testemunhos/CardTestemunhos";
import useMediaQuery from "../../../hooks/useMediaQuery ";

export default function CarrouselTestemunhos({ infoJson, allTestemunhos }) {
  const ismobile = useMediaQuery("(max-width: 574px)");

  const responsive = {
    0: {
      slidesPerView: 1,
    },
    375: {
      slidesPerView: 1.3,
    },
    574: {
      slidesPerView: 1,
    },
    767: {
      slidesPerView: 2,
    },
    1199: {
      slidesPerView: 3,
    },
  };

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <>
      <Swiper
        className={"tipos-slider"}
        breakpoints={responsive}
        // autoplay={ismobile ? false : true}
        pagination={ismobile ? {
          dynamicBullets: true,
        } : false}
        navigation={
          ismobile
            ? false
            : {
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
                disabledClass: css.buttonDisable,
                lockClass: css.buttonLock,
              }
        }
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        effect={"slide"}
        centeredSlides={ismobile ? true : false}
        centerInsufficientSlides={true}
        loop={false}
        speed={500}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {allTestemunhos.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Col className={`${cssAH.boxPadding}`}>
                <CardTestemunhos item={item} infoJson={infoJson} />
              </Col>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {ismobile ? (
        <></>
      ) : (
        <>
          <div className={`${css.buttonPrev} `} ref={navigationPrevRef}>
            <Arrow
              style={{ transform: `rotate(180deg)` }}
              height="60px"
              stroke={infoJson.Cores.ArrowSlider}
            />
          </div>
          <div className={`${css.buttonNext} `} ref={navigationNextRef}>
            <Arrow height="60px" stroke={infoJson.Cores.ArrowSlider} />
          </div>
        </>
      )}
    </>
  );
}
