import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Wrapper } from "./components/Wrapper";
import Home from "../pages/home/Home";
import { Spinner } from "react-bootstrap";
import PageHolder from "../tools/placeholder/PageHolder";
import Layout from "../Tpl/Layout";
import Perguntas from "../pages/perguntas/Perguntas";
import Funciona from "../pages/funciona/Funciona";

const Privacidade = lazy(() => import("../pages/privacidade/Privacidade"));
const Erro = lazy(() => import("../pages/erro/Erro"));
const Obrigado = lazy(() => import("../pages/obrigado/Obrigado"));
const Empresa = lazy(() => import("../pages/empresa/Empresa"));
const Contactos = lazy(() => import("../pages/contactos/Contactos"));
const Testemunhos = lazy(() => import("../pages/testemunhos/Testemunhos"));
const VenderSinistrado = lazy(() =>
  import("../pages/vendersinistrado/VenderSinistrado")
);
const SalvadosPortugal = lazy(() =>
  import("../pages/salvadosportugal/SalvadosPortugal")
);

const renderPaths = (paths, Element) =>
  paths.map((path) => <Route key={path} path={path} element={Element} />);

export default function Rotas({
  allinfo,
  setMetaTags,
  infoJson,
  allinfoText,
  infoPrincipal,
  pontosVenda,
}) {
  return (
    <>
      <Wrapper>
        <Routes>
          <Route
            element={
              <Layout
                infoJson={infoJson}
                infoPrincipal={infoPrincipal}
                allinfoText={allinfoText}
                allinfo={allinfo}
                pontosVenda={pontosVenda}
              />
            }
          >
            <Route
              path="/"
              element={
                <Home
                  infoJson={infoJson}
                  setMetaTags={setMetaTags}
                  allinfo={allinfo}
                  allinfoText={allinfoText}
                />
              }
            />

            {infoJson.QuemSomos.existe && (
              <Route
                path={
                  allinfoText.QuemSomos && allinfoText.QuemSomos.Url !== ""
                    ? allinfoText.QuemSomos.Url
                    : infoJson.QuemSomos.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Empresa
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />{" "}
                  </Suspense>
                }
              />
            )}
            {infoJson.PerguntasFrequentes.existe && (
              <Route
                path={
                  allinfoText.PerguntasFrequentes &&
                  allinfoText.PerguntasFrequentes.Url !== ""
                    ? allinfoText.PerguntasFrequentes.Url
                    : infoJson.PerguntasFrequentes.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Perguntas
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />{" "}
                  </Suspense>
                }
              />
            )}
            {infoJson.ComoFunciona.existe && (
              <Route
                path={
                  allinfoText.ComoFunciona &&
                  allinfoText.ComoFunciona.Url !== ""
                    ? allinfoText.ComoFunciona.Url
                    : infoJson.ComoFunciona.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Funciona
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />{" "}
                  </Suspense>
                }
              />
            )}

            {infoJson.Contactos.existe && (
              <Route
                path={
                  allinfoText.Contactos && allinfoText.Contactos.Url !== ""
                    ? allinfoText.Contactos.Url
                    : infoJson.Contactos.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Contactos
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}

            <Route
              path={"/privacidade/"}
              element={
                <Suspense fallback={<PageHolder />}>
                  <Privacidade infoJson={infoJson} setMetaTags={setMetaTags} />
                </Suspense>
              }
            />

            <Route
              path="/obrigado"
              element={
                <Suspense
                  fallback={
                    <div className="d-flex align-items-center justify-content-center">
                      <Spinner animation="border" />
                    </div>
                  }
                >
                  <Obrigado
                    allinfo={allinfo}
                    setMetaTags={setMetaTags}
                    infoJson={infoJson}
                  />
                </Suspense>
              }
            />

            {infoJson.Testemunhos.existe && (
              <Route
                path={
                  allinfoText.Testemunhos && allinfoText.Testemunhos.Url !== ""
                    ? allinfoText.Testemunhos.Url
                    : infoJson.Testemunhos.url
                }
                element={
                  <Suspense
                    fallback={
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    }
                  >
                    <Testemunhos
                      allinfo={allinfo}
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}

            {renderPaths(
              [`*`, `erro`],
              <Suspense
                fallback={
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" />
                  </div>
                }
              >
                <Erro setMetaTags={setMetaTags} infoJson={infoJson} />
              </Suspense>
            )}
          </Route>
          <Route
            path={"/vendersinistrado/"}
            element={
              <Suspense fallback={<PageHolder />}>
                <VenderSinistrado
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                  infoPrincipal={infoPrincipal}
                  setMetaTags={setMetaTags}
                  pontosVenda={pontosVenda}
                  BTNGradienteInicio={"#4394fe"}
                  BTNGradienteFim={"#a58fd8"}
                  NavGradienteFim={"#3e344d"}
                  NavGradienteInicio={"#15355c"}
                  website={"vendersinistrado.pt"}
                  titleMeta={"Vender Sinistrado"}
                  logo={"/assets/img/VenderSinistrado/logo.webp"}
                  banner={"/assets/img/VenderSinistrado/hd.webp"}
                  bannerMob={"/assets/img/VenderSinistrado/hd_mob1.webp"}
                  partilhar={"/assets/img/VenderSinistrado/partilha_fb.jpg"}
                  favicon={"/assets/img/VenderSinistrado/favicon.png"}
                />
              </Suspense>
            }
          />
          <Route
            path={"/compramososeusalvado/"}
            element={
              <Suspense fallback={<PageHolder />}>
                <VenderSinistrado
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                  infoPrincipal={infoPrincipal}
                  setMetaTags={setMetaTags}
                  pontosVenda={pontosVenda}
                  BTNGradienteInicio={"#e34f2d"}
                  BTNGradienteFim={"#bb3f23"}
                  NavGradienteFim={"#521c10"}
                  NavGradienteInicio={"#43160b"}
                  website={"compramososeusalvado.pt"}
                  titleMeta={"Compramos o seu Salvado"}
                  logo={"/assets/img/CompramosSalvado/logo.webp"}
                  banner={"/assets/img/CompramosSalvado/hd.webp"}
                  bannerMob={"/assets/img/CompramosSalvado/hd_mob1.webp"}
                  partilhar={"/assets/img/CompramosSalvado/partilha_fb.jpg"}
                  favicon={"/assets/img/CompramosSalvado/favicon.png"}
                />
              </Suspense>
            }
          />
          <Route
            path={"/compramososeusinistrado/"}
            element={
              <Suspense fallback={<PageHolder />}>
                <VenderSinistrado
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                  infoPrincipal={infoPrincipal}
                  setMetaTags={setMetaTags}
                  pontosVenda={pontosVenda}
                  BTNGradienteInicio={"#fec321"}
                  BTNGradienteFim={"#e7a108"}
                  NavGradienteFim={"#5c480d"}
                  NavGradienteInicio={"#543902"}
                  website={"compramososeusinistrado.pt"}
                  titleMeta={"Compramos o seu Sinistrado"}
                  logo={"/assets/img/CompramosSinistrado/logo.webp"}
                  banner={"/assets/img/CompramosSinistrado/hd.webp"}
                  bannerMob={"/assets/img/CompramosSinistrado/hd_mob1.webp"}
                  partilhar={"/assets/img/CompramosSinistrado/partilha_fb.jpg"}
                  favicon={"/assets/img/CompramosSinistrado/favicon.png"}
                />
              </Suspense>
            }
          />

          <Route
            path={"/sinistrados/"}
            element={
              <Suspense fallback={<PageHolder />}>
                <SalvadosPortugal
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                  infoPrincipal={infoPrincipal}
                  setMetaTags={setMetaTags}
                  pontosVenda={pontosVenda}
                  BTNGradienteInicio={"#e63031"}
                  BTNGradienteFim={"#bf2130"}
                  NavGradienteFim={"#551011"}
                  NavGradienteInicio={"#450b11"}
                  website={"sinistrados.pt"}
                  titleMeta={"Sinistrado"}
                  logo={"/assets/img/Sinistrados/logo.webp"}
                  banner={"/assets/img/Sinistrados/hd.webp"}
                  bannerMob={"/assets/img/Sinistrados/hd_mob1.webp"}
                  partilhar={"/assets/img/Sinistrados/partilha_fb.jpg"}
                  favicon={"/assets/img/Sinistrados/favicon.png"}
                />
              </Suspense>
            }
          />
          <Route
            path={"/salvadosportugal"}
            element={
              <Suspense fallback={<PageHolder />}>
                <SalvadosPortugal
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                  infoPrincipal={infoPrincipal}
                  setMetaTags={setMetaTags}
                  pontosVenda={pontosVenda}
                  BTNGradienteInicio={"#eb771a"}
                  BTNGradienteFim={"#c05d14"}
                  NavGradienteFim={"#562b09"}
                  NavGradienteInicio={"#452107"}
                  website={"salvadosportugal.pt"}
                  titleMeta={"Salvados Portugal"}
                  logo={"/assets/img/SalvadosPortugal/logo.webp"}
                  banner={"/assets/img/SalvadosPortugal/hd.webp"}
                  bannerMob={"/assets/img/SalvadosPortugal/hd_mob1.webp"}
                  partilhar={"/assets/img/SalvadosPortugal/partilha_fb.jpg"}
                  favicon={"/assets/img/SalvadosPortugal/favicon.png"}
                />
              </Suspense>
            }
          />
        </Routes>
      </Wrapper>
    </>
  );
}
