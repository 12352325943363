import React from "react";
import { Col, Row } from "react-bootstrap";
import { BtnSimNao } from "./EasybuyMetalizado";
import css from "./easybuyhistorico.module.css";

export default function EasybuyHistorico({ setFieldValue, values }) {
  const init = [
    {
      name: "Primeiro dono",
      key: "historico.primeiroDono",
      values: values.historico.primeiroDono,
    },
    {
      name: "Livro de Revisões",
      key: "historico.livroRevisao",
      values: values.historico.livroRevisao,
    },
    {
      name: "Veiculo de Fumador",
      key: "historico.fumador",
      values: values.historico.fumador,
    },
    {
      name: "Veiculo Importado",
      key: "historico.importado",
      values: values.historico.importado,
    },
    {
      name: "Acidentes Reportados",
      key: "historico.acidentes",
      values: values.historico.acidentes,
    },
    {
      name: "Tem segunda chave?",
      key: "historico.segundaChave",
      values: values.historico.segundaChave,
    },
  ];
  return (
    <Row xs={1} md={2} xl={3}>
      {init.map((item, index) => (
        <Col className={`${css.box}`} key={index}>
          <div className={`${css.rectangule}`}>
            <div className={css.label}>{item.name}</div>
            <BtnSimNao
              checked={item.values === 1}
              onClick={() => {
                setFieldValue(item.key, 1);
              }}
              id={1}
            >
              Sim
            </BtnSimNao>
            <BtnSimNao
              checked={item.values === 0}
              onClick={() => {
                setFieldValue(item.key, 0);
              }}
              id={2}
            >
              Nao
            </BtnSimNao>
          </div>
        </Col>
      ))}
    </Row>
  );
}
