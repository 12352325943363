import React from "react";
import { Col, Row } from "react-bootstrap";
import css from "./easybuypneus.module.css";

import bom_on from "../../../../../../assets/icons/easybuy/bom_on.svg";
import bom_off from "../../../../../../assets/icons/easybuy/bom.svg";
import mau_on from "../../../../../../assets/icons/easybuy/mau_on.svg";
import mau_off from "../../../../../../assets/icons/easybuy/mau.svg";
import medio_on from "../../../../../../assets/icons/easybuy/medio_on.svg";
import medio_off from "../../../../../../assets/icons/easybuy/medio.svg";

const icon = (checked, id) => {
  switch (id) {
    case 1:
      return <img src={checked ? bom_on : bom_off} alt={"bom"} />;
    case 2:
      return <img src={checked ? medio_on : medio_off} alt={"medio"} />;
    case 3:
      return <img src={checked ? mau_on : mau_off} alt={"mau"} />;

    default:
      return <img src={checked ? bom_on : bom_off} alt={"bom"} />;
  }
};

const BtnBomMau = ({ checked, children, onClick, id }) => {
  return (
    <div
      className={`${css.radio} ${css.padding} ${css.rigth} ${
        checked ? css.activo : ""
      }`}
      onClick={onClick}
    >
      {children}
      {icon(checked, id)}
    </div>
  );
};

export default function EasybuyPneus({ setFieldValue, values }) {
  const init = [
    {
      name: "Dianteiros",
      key: "pneus.dianteiros",
      values: values.pneus.dianteiros,
    },
    {
      name: "Traseiros",
      key: "pneus.traseiros",
      values: values.pneus.traseiros,
    },
  ];
  return (
    <Row xs={1} xl={2}>
      {init.map((item, index) => (
        <Col className={`${css.boxFather}`} key={index}>
          <div className={`${css.rectanguleFather}`}>
            <div className={css.labelFather}>{item.name}</div>
            <BtnBomMau
              checked={item.values === 2}
              onClick={() => {
                setFieldValue(item.key, 2);
              }}
              id={1}
            >
              Bom
            </BtnBomMau>
            <BtnBomMau
              checked={item.values === 1}
              onClick={() => {
                setFieldValue(item.key, 1);
              }}
              id={2}
            >
              Médio
            </BtnBomMau>
            <BtnBomMau
              checked={item.values === 0}
              onClick={() => {
                setFieldValue(item.key, 0);
              }}
              id={3}
            >
              Mau
            </BtnBomMau>
          </div>
        </Col>
      ))}
    </Row>
  );
}
