import React from "react";
import { theme } from "../../../tools/email/templates/Signup/theme";
import { WithDebug } from "../../../tools/email/templates/HtmlEmail";
import { A, Span } from "react-html-email";

export default function EmailRemetenteEasybuy({
  campos,
  infoJson,
  allinfo,
  camposEmail,
  data,
  allinfoText,
}) {
  
  return (
    <>
      <WithDebug
        theme={theme}
        title={camposEmail.assunto}
        emailHeaderHref={`http://${infoJson.Stand.Website}`}
        emailHeaderText={() => {
          return (
            <>
              <Span>
                <h1 style={theme.emailHeader.emailHeaderText}>
                  MENSAGEM ENVIADA
                </h1>
                <A
                  href={`http://${infoJson.Stand.Website}`}
                  textDecoration={theme.emailHeader.a.textDecoration}
                  style={theme.emailHeader.a}
                >
                  por {`${infoJson.Stand.Website}`}
                </A>
              </Span>
            </>
          );
        }}
        emailHeaderLogo={allinfo.LogotipoEasybuy}
        bodyHeaderText={() => {
          return (
            <>
              <p style={theme.emailBody.bodyHeader.small}>
                Caro/a {campos.nome}, a sua viatura:
                <br></br>
                <b>
                  {campos.marca.nome} {campos.modelo.nome} com a matrícula{" "}
                  {campos.matricula}
                </b>
                <br></br>
                <b>foi submetida para avaliação </b>
                <br></br>
                Codigo Oferta:
                <b>{data.cod_viatura}</b>
              </p>
            </>
          );
        }}
        bodyContentComponent={() => {
          return (
            <>
              <p
                style={theme.emailBody.bodyFooter.span}
                dangerouslySetInnerHTML={{
                  __html: allinfoText.CondicoesLegal.Zona,
                }}
              />
            </>
          );
        }}
        bodyFooterText={() => {
          return (
            <>
              <p style={theme.emailBody.bodyFooter.span}>
                <>Aguarde novo contacto para a avaliação</>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                <b>{allinfo.NomeEmpresa}</b>
              </p>
              <p style={theme.emailBody.bodyFooter.spanNoMargin}>
                Telef:<b>{allinfo.Telemovel}</b>
              </p>
              <p style={theme.emailBody.bodyFooter.spanNoMarginA}>
                Email: <b>{allinfo.Email}</b>
              </p>
            </>
          );
        }}
        debug={false}
      />
    </>
  );
}
