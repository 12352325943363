import { getIn } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import * as apiCarros from "../../../../../../api/apiCarros";

export default function EasybuyLugares({
  errors,
  handleChange,
  setFieldValue,
  touched,
  infoJson,
  values,
  setExtras,
  allVersoesSelected,
}) {
  const [allLugares, setAllLugares] = useState([]);

  useEffect(() => {
    const getLugares = async () => {
      const restosEurotax = await apiCarros.GetDataByVersionEurotax(
        values.versao.id
      );

      if (restosEurotax === 0) {
        const lugares = await apiCarros.getAllLugares(infoJson);
        setAllLugares(lugares);
      } else {
        setAllLugares([
          {
            CodLugar: restosEurotax.Lugares.CodLugar,
            Lugar: restosEurotax.Lugares.Lugar,
          },
          {
            CodLugar: "Outra Opção",
            Lugar: "Outra Opção",
          },
        ]);

        setFieldValue("lugar.id", restosEurotax.Lugares.CodLugar);
        setFieldValue("lugar.nome", restosEurotax.Lugares.Lugar);

        if (restosEurotax.Extras.length !== 0) {
          setExtras(restosEurotax.Extras);

          let arr = [];

          restosEurotax.Extras.forEach((item) => {
            item.opcao === null
              ? (arr = [...arr, item.nome])
              : (arr = [...arr, item.opcao]);
          });
          setFieldValue("extras", arr);
        }
      }
    };
    getLugares();
  }, [values.versao.id, infoJson, setFieldValue, setExtras]);

  useEffect(() => {
    if (allVersoesSelected.length !== 0) {
      setFieldValue("lugar.id", allVersoesSelected.Cod_lugares);
      setFieldValue("lugar.nome", allVersoesSelected.Lugares);
    }
  }, [allVersoesSelected, setFieldValue]);

  const onChangeLugares = async (event) => {
    event.persist();

    if (event.target.value === "Outra Opção") {
      const lugares = await apiCarros.getAllLugares(infoJson);
      setAllLugares(lugares);
      setFieldValue("lugar.id", "");
      setFieldValue("lugar.nome", "");
    }
  };

  return (
    <>
      <Form.Select
        name={handleChange.name}
        onBlur={handleChange.onBlur}
        onChange={(e) => {
          handleChange.onChange(e);
          onChangeLugares(e);
          setFieldValue(
            "lugar.nome",
            e.target[e.target.selectedIndex].getAttribute("data-value")
          );
        }}
        isInvalid={
          Boolean(getIn(errors, "lugar.id")) &&
          Boolean(getIn(touched, "lugar.id"))
        }
        isValid={handleChange.value !== ""}
        value={handleChange.value}
      >
        <option value="" data-value="">
          Selecione
        </option>
        {allLugares.map((Lugares) => {
          const { CodLugar, Lugar } = Lugares;
          return (
            <option key={CodLugar} value={CodLugar} data-value={Lugar}>
              {Lugar}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {getIn(errors, "lugar.id")}
      </Form.Control.Feedback>
    </>
  );
}
