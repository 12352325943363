import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as Mais } from "../../../../../../assets/icons/mais.svg";
import * as apiCarros from "../../../../../../api/apiCarros";
import check_on from "../../../../../../assets/icons/easybuy/check_on.svg";
import check_off from "../../../../../../assets/icons/easybuy/check.svg";

import css from "./easybuycores.module.css";

const CheckCores = ({ checked, onClick, Hexa, children }) => {
  return (
    <div
      className={`${css.radio} ${checked ? css.activo : ""}`}
      onClick={onClick}
    >
      <div className={`${css.box}`} style={{ backgroundColor: Hexa }}></div>{" "}
      {children}
      <img src={checked ? check_on : check_off} alt={"check"} />
    </div>
  );
};

export default function EasybuyCores({ values, setFieldValue, infoJson }) {
  const [allCores, setAllCores] = useState([]);
  const [addClassCores, setAddClassCores] = useState("");
  const [addNoneCores, setAddNoneCores] = useState("inline-block");
  const handleReadMoreCores = () => {
    setAddClassCores("sidebar-box-Max");
    setAddNoneCores("none");
  };

  useEffect(() => {
    const getCores = async () => {
      const cores = await apiCarros.getAllCores(infoJson);
      setAllCores(cores);
    };
    getCores();
  }, [infoJson]);

  return (
    <Col
      xs={12}
      className={` nopadding sidebar-box ${addClassCores} sidebar-box-cores `}
    >
      <Row xs={2} sm={3} md={4} ld={4} xl={5} xxl={6}>
        {allCores.map((Cores) => {
          const { CodCor, Cor, Hexa } = Cores;
          return (
            <Col key={CodCor} className="mb-3">
              <CheckCores
                checked={values.cor.id === CodCor}
                onClick={() => {
                  setFieldValue("cor.id", CodCor);
                  setFieldValue("cor.nome", Cor);
                }}
                Hexa={Hexa}
              >
                {Cor}
              </CheckCores>
            </Col>
          );
        })}
      </Row>
      <div className="read-more" style={{ display: `${addNoneCores}` }}>
        <button
          onClick={handleReadMoreCores}
          className={` btnPesquisa ${css.btn}`}
          style={{ width: "auto", padding: " 0 10px" }}
        >
          ver mais cores{" "}
          <Mais style={{ marginLeft: "10px", stroke: "#ffffff" }} />
        </button>
      </div>
    </Col>
  );
}
