import { getIn } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import * as apiCarros from "../../../../../../api/apiCarros";

export default function EasybuyMarcas({
  onChangeMarcas,
  errors,
  setFieldValue,
  handleChange,
  touched,
  infoJson,
  values,
  allVersoesSelected,
}) {
  const [allMarcas, setAllMarcas] = useState([]);
  useEffect(() => {
    const getAllMarcas = async () => {
      const marcas = await apiCarros.getAllMarcas(infoJson);
      setAllMarcas(marcas);
    };
    getAllMarcas();
  }, [infoJson]);

  useEffect(() => {
    if (allVersoesSelected.length !== 0) {
      var element = document.getElementById(handleChange.name);
      var trigger = Object.getOwnPropertyDescriptor(
        window.HTMLSelectElement.prototype,
        "value"
      ).set;
      trigger.call(element, allVersoesSelected.Cod_marca);
      var event = new Event("change", { bubbles: true });
      element.dispatchEvent(event);

      setFieldValue("marca.id", allVersoesSelected.Cod_marca);
      setFieldValue("marca.nome", allVersoesSelected.Marca);
    }
  }, [allVersoesSelected, setFieldValue, handleChange.name]);

  const onChangeReset = () => {
    setFieldValue("modelo.nome", "");
    setFieldValue("modelo.id", "");
    setFieldValue("combustivel.nome", "");
    setFieldValue("combustivel.id", "");
    setFieldValue("potencia", "");
    setFieldValue("cilindrada", "");
    setFieldValue("porta.nome", "");
    setFieldValue("porta.id", "");
    setFieldValue("transmissao.nome", "");
    setFieldValue("transmissao.id", "");
    setFieldValue("versao.nome", "");
    setFieldValue("versao.id", "");
    setFieldValue("lugar.nome", "");
    setFieldValue("lugar.id", "");
  };

  return (
    <>
      <Form.Select
        id={handleChange.name}
        name={handleChange.name}
        onBlur={handleChange.onBlur}
        onChange={(e) => {
          onChangeMarcas(e);
          onChangeReset();
          handleChange.onChange(e);
          setFieldValue(
            "marca.nome",
            e.target[e.target.selectedIndex].getAttribute("data-value")
          );
        }}
        isInvalid={
          Boolean(getIn(errors, "marca.id")) &&
          Boolean(getIn(touched, "marca.id"))
        }
        isValid={handleChange.value !== ""}
        disabled={values.ano !== "" ? false : true}
        value={handleChange.value}
      >
        <option value="">Selecione</option>
        {allMarcas.map((marca) => {
          const { Codigo, Nome } = marca;
          return (
            <option key={Codigo} value={Codigo} data-value={Nome}>
              {Nome}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {getIn(errors, "marca.id")}
      </Form.Control.Feedback>
    </>
  );
}
